import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-bst-badge',
    templateUrl: './bst-badge.component.html',
    styleUrls: ['./bst-badge.component.scss'],
})
export class BstBadgeComponent {
    @Input() view: 'mini' | 'compact' | 'normal' = 'normal';
    @Input() colorClass = 'bst';
}
